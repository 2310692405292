@import "../../styles/_media-queries.scss";

.about-us {
    padding: 36px 0;
    position: relative;

    @include only-phone {
        padding: 24px 0;
    }

    .about-us-content {
        .about-us-row {
            display: flex;
            align-items: center;
            padding-left: 30px;

            @include only-phone-lg {
                flex-direction: column;
                padding-left: 0;
            }

            .about-us-img-wrapper {
                max-width: 538px;
                position: relative;
                margin-right: 60px;

                @include only-phone-lg {
                    max-width: 400px;
                    margin-right: 0;
                    margin-bottom: 60px;
                }

                .about-us-img {
                    width: 100%;
                }

                .esphere {
                    width: 188px;
                    height: 188px;
                    position: absolute;
                    z-index: -1;
                    top: -5px;
                    left: 0px;

                    @include only-phone-xl {
                        width: 150px;
                        height: 150px;
                        top: -10px;
                    }

                    @include only-phone-lg {
                        width: 150px;
                        height: 150px;
                        top: -10px;
                    }

                    @include only-phone-xs {
                        width: 130px;
                        height: 130px;
                    }
                }
            }

            .about-us-txt {
                max-width: 628px;
                margin-left: auto;

                @include only-phone-xl {
                    max-width: 500px;
                }

                @include only-phone-lg {
                    max-width: 550px;
                    margin-left: 0;
                }

                .small-title {
                    display: flex;
                    align-items: center;
                    font-family: Poppins, sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 1.62px;
                    text-transform: uppercase;
                    margin-bottom: 36px;

                    @include only-phone-xl {
                        font-size: 16px;
                        margin-bottom: 30px;
                    }

                    @include only-phone {
                        font-size: 12px;
                        margin-bottom: 24px;
                    }

                    &::before {
                        content: '';
                        display: block;
                        width: 100px;
                        height: 2px;
                        background-color: #FF8C00;
                        margin-right: 16px;

                        @include only-phone {
                            width: 60px;
                        }
                    }
                }

                .title {
                    font-family: Quantico, sans-serif;
                    font-size: 42px;
                    font-weight: 700;
                    line-height: 1.37;
                    letter-spacing: -0.42px;
                    margin-bottom: 24px;

                    @include only-phone-xl {
                        font-size: 32px;
                    }

                    @include only-phone-md {
                        font-size: 32px;
                    }

                    @include only-phone {
                        font-size: 28px;
                    }

                    @include only-phone-xs {
                        font-size: 24px;
                    }
                }

                .description {
                    text-align: justify;
                    font-family: Poppins, sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.94;
                    margin-bottom: 42px;

                    @include only-phone-xl {
                        font-size: 14px;
                        margin-bottom: 36px;
                    }

                    @include only-phone {
                        font-size: 14px;
                        line-height: 1.6;
                    }

                    @include only-phone-xs {
                        font-size: 12px;
                        line-height: 1.5;
                    }
                }

                .about-us-btns {
                    .white-paper-btn {
                        display: inline-block;
                        text-align: center;
                        min-width: 260px;

                        @include only-phone {
                            min-width: 200px;
                        }
                    }
                }
            }
        }
    }

    .light-2 {
        width: 425px;
        height: 380.274px;
        border-radius: 50%;
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 140, 0, 0.80) 0%, rgba(17, 17, 19, 0.00) 100%);
        mix-blend-mode: screen;
        position: absolute;
        right: -300px;
        top: -200px;

        @include only-phone-lg {
            width: 300px;
            height: 268px;
            right: -215px;
            top: 350px;
        }

        @include only-phone-md {
            width: 250px;
            height: 250px;
            right: -180px;
        }

        @include only-phone {
            width: 200px;
            height: 200px;
            right: -140px;
        }

        @include only-phone-xs {
            top: 250px;
        }
    }
}