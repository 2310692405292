@import "../../styles/_media-queries.scss";

.header {
    padding: 32px 0;

    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-left {
            width: 150px; // same as right in order to center the nav

            .header-logo {
                height: 56px;

                @include only-phone-xl {
                    display: none;
                }
            }

            .sidebar-toggle {
                width: 50px;
                height: 50px;
                color: #ffffff;
                background: #f0f0f000;
                display: none;
                border-radius: 16px;
                transition: all 200ms ease-in-out;

                &:hover {
                    background: #f0f0f010;
                }

                @include only-phone-xl {
                    display: block;
                }

                svg {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        .header-center {
            @include only-phone-xl {
                display: none;
            }

            .header-nav {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 -30px;

                li {
                    padding: 0 30px;
                }

                a {
                    transition: color 0.2s;

                    &:hover {
                        color: var(--Primary-2, #FF8C00);
                    }

                    &.active {
                        color: var(--Primary-2, #FF8C00);
                    }
                }
            }
        }

        .header-right {
            width: 150px; // same as left in order to center the nav

            .launch-dapp-btn {
                min-width: 150px;
            }
        }
    }

    .sidebar-backdrop {
        background-color: rgba(0, 0, 0, .7);
        height: 100vh;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: 0;
        transition: opacity .2s ease-in-out, backdrop-filter .2s ease-in-out;
        width: 100%;
        z-index: 999;
        backdrop-filter: blur(0px);

        &.active {
            opacity: 1;
            pointer-events: auto;
            backdrop-filter: blur(6px);
        }
    }

    .sidebar {
        background: #2A2A2F;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        transform: translateX(-100%);
        transition: transform .3s ease-in-out;
        width: 250px;
        z-index: 1000;
        overflow-y: auto;

        &.active {
            transform: translateX(0);
        }

        .sidebar-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 24px;
            padding-bottom: 0;

            .sidebar-logo {
                display: flex;
                margin-bottom: 8px;

                img {
                    height: 40px;
                    margin-right: 16px;
                }

                span {
                    font-family: Quantico;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    margin-bottom: 16px;
                }
            }

            .sidebar-nav {
                .sidebar-nav-list {
                    li {
                        padding: 12px 0;

                        a {
                            &:hover {
                                color: var(--Primary-2, #FF8C00);
                            }

                            &.active {
                                color: var(--Primary-2, #FF8C00);
                            }

                            svg {
                                width: 24px;
                                height: 24px;
                                margin-right: 16px;
                            }
                        }
                    }
                }
            }

            .sidebar-footer {
                margin-top: auto;
                padding-top: 24px;

                .copyright {
                    font-size: 12px;
                    color: #8E8E93;
                    text-align: center;
                    margin-bottom: 16px;
                }

                .socials {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 16px;

                    .social-item {
                        margin-right: 11px;

                        &:last-child {
                            margin-right: 0;
                        }

                        a {
                            display: block;
                            width: 24px;

                            svg {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}