@import "../../styles/_media-queries.scss";

.key-features {
    padding: 70px 0 190px 0;

    @include only-phone {
        padding: 60px 0 100px 0;
    }

    .key-features-content {

        .key-features-title {
            margin-bottom: 100px;

            @include only-phone-lg {
                margin-bottom: 50px;
            }

            @include only-phone {
                margin-bottom: 50px;
            }
            
            .small-title {
                color: var(--Primary-2, #FF8C00);
                text-align: center;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 1.62px;
                text-transform: uppercase;
                margin-bottom: 32px;

                @include only-phone {
                    font-size: 12px;
                    margin-bottom: 20px;
                }
            }

            .title {
                text-align: center;
                font-family: Quantico;
                font-size: 59.684px;
                font-weight: 700;
                letter-spacing: -0.597px;

                @include only-phone-xl {
                    font-size: 32px;
                }

                @include only-phone-md {
                    font-size: 32px;
                }

                @include only-phone {
                    font-size: 28px;
                }

                @include only-phone-xs {
                    font-size: 24px;
                }
            }
        }

        .key-features-cards {
            --bs-gutter-x: 80px;
            --bs-gutter-y: 40px;

            @include only-phone-lg {
                --bs-gutter-x: 40px;
                --bs-gutter-y: 30px;
            }
            
            @include only-phone {
                --bs-gutter-x: 30px;
                --bs-gutter-y: 30px;
            }

            .key-feature-item {
                width: 50%;

                @include only-phone-lg {
                    width: 100%;
                }
            }
        }

        .key-feature-card {
            height: 100%;
            padding: 65px 50px;
            padding-top: 30px;

            @include only-phone {
                padding: 40px 30px;
            }

            .kf-icon {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                border: 4px solid rgba(255, 255, 255, 0.06);
                background: rgba(255, 255, 255, 0.07);
                margin-bottom: 27px;
                display: flex;
                justify-content: center;
                align-items: center;

                @include only-phone {
                    width: 60px;
                    height: 60px;
                }
            }

            .kf-title {
                font-family: Poppins;
                font-size: 22px;
                font-weight: 500;
                margin-bottom: 27px;

                @include only-phone {
                    font-size: 18px;
                    margin-bottom: 20px;
                }
            }

            .kf-description {
                font-family: Poppins;
                font-size: 13px;
                line-height: 22px;

                @include only-phone {
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }
    }
}
