@import "../../styles/_media-queries.scss";

.footer {
    background: #2A2A2F;

    .footer-content {   
        .footer-row {
            padding: 100px 0;
            display: flex;

            @include only-phone-md{
                flex-direction: column;
                padding: 50px 0;
                align-items: center;
            }

            .footer-about {
                max-width: 358px;

                .footer-logo {
                    height: 92px;
                    margin-bottom: 24px;
                }

                .footer-about-title {
                    font-family: Quantico;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    margin-bottom: 16px;
                }

                .footer-about-text {
                    text-align: justify;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .footer-nav {
                margin-left: 276px;

                @include only-phone-lg{
                    margin-left: 130px;
                }

                @include only-phone-md{
                    margin-left: 0;
                    margin-top: 50px;
                    width: 100%;
                    max-width: 358px;
                }

                .footer-nav-title {
                    font-family: Poppins;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    margin-bottom: 35px;

                    @include only-phone-md{
                        margin-bottom: 0px;
                    }
                }

                .footer-nav-links {
                    display: flex;
                    flex-direction: column;
                    margin-top: 40px;

                    @include only-phone-md{
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-top: 24px;
                    }

                    li {
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 22px;
                        /* 137.5% */
                        margin-bottom: 23px;

                        @include only-phone-md{
                            flex: 0 0 50%;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a{
                            transition: color 0.3s;
                            color: #F0F8FF;

                            &:hover{
                                color: #FF8C00;
                            }
                        }
                    }
                }
            }
        }

        .footer-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 0;
            border-top: 1px solid #F0F8FF;

            @include only-phone-md{
                flex-direction: column;
                padding: 20px 0;
            }

            .copyright {
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
            }

            .socials{
                display: flex;
                align-items: center;
                
                @include only-phone-md{
                    margin-top: 20px;
                }

                .social-item{
                    margin-right: 11px;

                    &:last-child{
                        margin-right: 0;
                    }

                    a {
                        display: block;
                        width: 24px;
                        transition: color 0.3s;
                        color: #F0F8FF;

                        &:hover{
                            color: #FF8C00;
                        }
                        
                        svg{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}