@import "../../styles/_media-queries.scss";

.hero {
    position: relative;

    .hero-content {
        position: relative;
        min-height: 780px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include only-phone-lg {
            min-height: 400px;
        }

        @include only-phone-md {
            min-height: 300px;
        }

        .hero-title {
            color: var(--White-var, #F4F4F4);
            font-family: Quantico;
            font-size: 185px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: -5.55px;
            text-transform: uppercase;
            width: 100%;
            margin-top: -60px;

            @include only-phone-lg {
                font-size: 120px;
                letter-spacing: -3.5px;
                margin-top: -40px;
            }

            @include only-phone-md {
                font-size: 100px;
                letter-spacing: -2.5px;
                margin-top: -30px;
            }

            @include only-phone {
                font-size: 80px;
                letter-spacing: -2px;
                margin-top: -20px;
            }

            @include only-phone-xs {
                font-size: 60px;
                letter-spacing: -1.5px;
                margin-top: -10px;
            }

            .proxy {
                display: block;
            }

            .network {
                display: block;
                text-align: right;
                margin-top: 13px;

                @include only-phone-lg {
                    margin-top: 20px;
                }
            }
        }

        #particles {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            padding: 0 80px;
            pointer-events: none;

            @include only-phone {
                padding: 0 30px;
            }

            @include only-phone-md {
                padding: 0 40px;
            }
        }
    }

    .light-1 {
        border-radius: 50%;
        width: 425px;
        height: 437.274px;
        background: radial-gradient(50% 50% at 50% 50%, #FF8C00 0%, rgba(17, 17, 19, 0.00) 100%);
        mix-blend-mode: screen;
        position: absolute;
        left: -330px;
        bottom: 0;

        @include only-phone-lg {
            width: 350px;
            height: 350px;
            left: -250px;
            bottom: -100px;
        }

        @include only-phone-md {
            width: 250px;
            height: 250px;
            left: -180px;
            bottom: -100px;
        }

        @include only-phone {
            width: 200px;
            height: 200px;
            left: -140px;
            bottom: -40px;
        }
    }
}