@import "../../styles/media-queries";

.tokenomics-bottom {
    padding: 64px 0;
    padding-bottom: 164px;
    position: relative;

    @include only-phone {
        padding: 40px 0;
        padding-bottom: 80px;
    }

    .tokenomics-bottom-content {
        .title {
            text-align: center;
            font-family: Poppins, sans-serif;
            font-size: 32px;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 70px;

            @include only-phone {
                font-size: 24px;
                margin: 0 auto;
                margin-bottom: 40px;
                max-width: 300px;
            }
        }

        .tokenomics-cards {
            display: flex;
            justify-content: space-between;
            margin-bottom: 100px;

            @include only-phone-xl {
                align-items: center;
                flex-direction: column;
                margin-bottom: 50px;
            }

            .tokenomics-card-item {
                width: 350px;

                @include only-phone-xl {
                    margin-bottom: 24px;
                }
            }

            .tokenomics-card {
                padding: 26px 0;
                height: 100%;

                @include only-phone {
                    padding: 20px 0;
                }

                .tokenomics-card-title {
                    padding-bottom: 12px;
                    color: var(--Primary-2, #FF8C00);
                    font-family: Poppins, sans-serif;
                    font-size: 18px;
                    font-weight: 600;
                    text-align: center;

                    @include only-phone {
                        font-size: 16px;
                    }
                }

                .divider {
                    width: 100%;
                    padding: 0 58px;

                    &::before {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: rgba(255, 255, 255, 0.20);
                    }

                    @include only-phone {
                        padding: 0 30px;
                    }
                }

                .tokenomics-card-text {
                    max-width: 293px;
                    margin: 0 auto;
                    padding-top: 12px;
                    color: var(--Text, #F0F8FF80);
                    text-align: center;
                    font-family: Poppins, sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.1;
                    letter-spacing: -0.48px;

                    @include only-phone {
                        font-size: 12px;
                        line-height: 1.5;
                    }
                }
            }
        }

        .description {
            text-align: center;
            font-family: Poppins, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 45px;
            letter-spacing: -0.48px;

            @include only-phone {
                font-size: 14px;
                line-height: 1.6;
                margin-top: 40px;
            }
        }
    }

    .tokenomics-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;

        @include only-phone {
            object-position: center;
        }
    }
}
