.gradient-box {
    position: relative;
    box-sizing: border-box;

    $border: 1px;
    color: #FFF;
    background: #141416;
    background-clip: padding-box;
    border: solid $border transparent;
    border-radius: 1em;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: linear-gradient(190deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 140, 0, 1) 100%);
    }

    /* Shine and hover effects */
    --shine-deg: 45deg;
    position: relative;
    background-repeat: no-repeat;
    background-position: -100% 0,
    0 0;

    background-image: linear-gradient(var(--shine-deg),
        transparent 20%,
        transparent 40%,
        rgb(68, 68, 68, 0.2) 50%,
        rgb(68, 68, 68, 0.2) 55%,
        transparent 70%,
        transparent 100%);

    background-size: 250% 250%,
    100% 100%;
    transition: background-position 0s ease-in-out
    box-shadow 0.2s ease-in-out;
    box-shadow: 0 0 16px rgba(255, 140, 0, 0);

    &:hover {
        background-position: 200%0, 0 0;
        transition: background-position 1s ease-in-out, box-shadow 0.2s ease-in-out;
        box-shadow: 0 0 16px rgba(255, 140, 0, 0.3);
    }
}