@import "../../styles/_media-queries.scss";

.contact-us {
    padding: 164px 0;
    position: relative;

    @include only-phone {
        padding: 80px 0;
    }

    .contact-us-content {
        .contact-us-row {
            display: flex;
            align-items: center;
            padding: 30px 30px 30px 90px;

            @include only-phone-xl {
                padding: 30px;
            }

            @include only-phone-lg {
                flex-direction: column-reverse;
                padding: 30px;
                max-width: max-content;
                margin: 0 auto;
            }

            /* border gradient start */
            position: relative;
            box-sizing: border-box;
            $border: 2px;
            color: #FFF;
            background: #141416;
            background-clip: padding-box;
            border: solid $border transparent;
            border-radius: 1em;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                margin: -$border;
                border-radius: inherit;
                background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 140, 0, 1) 100%);
            }
            /* border gradient end */

            .contact-us-txt {
                max-width: 553px;
                margin-right: auto;

                @include only-phone-xl {
                    max-width: 500px;
                }

                @include only-phone-lg {
                    max-width: 550px;
                    margin-right: 0;
                    margin-top: 40px;
                }

                .small-title {
                    color: var(--Primary-2, #FF8C00);
                    font-family: Poppins, sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 1.62px;
                    text-transform: uppercase;
                    margin-bottom: 24px;

                    @include only-phone-xl {
                        font-size: 16px;
                    }

                    @include only-phone-lg {
                        font-size: 14px;
                    }

                    @include only-phone {
                        font-size: 10px;
                        margin-bottom: 16px;
                    }
                }

                .title {
                    font-family: Quantico, sans-serif;
                    font-size: 42px;
                    font-weight: 700;
                    line-height: 1.37;
                    letter-spacing: -0.42px;
                    margin-bottom: 24px;

                    @include only-phone-xl {
                        font-size: 32px;
                    }

                    @include only-phone-md {
                        font-size: 32px;
                    }

                    @include only-phone {
                        font-size: 20px;
                        margin-bottom: 16px;
                    }

                    @include only-phone-xs {
                        font-size: 18px;
                    }
                }

                .description {
                    font-family: Poppins, sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.94;
                    margin-bottom: 36px;

                    @include only-phone-xl {
                        font-size: 14px;
                    }

                    @include only-phone {
                        font-size: 14px;
                        line-height: 1.6;
                        margin-bottom: 24px;
                    }

                    @include only-phone-xs {
                        font-size: 12px;
                        line-height: 1.5;
                    }
                }

                .contact-us-btns {
                    display: flex;
                    align-items: center;

                    @include only-phone {
                        flex-direction: column;
                    }

                    .contact-us-btn {
                        display: flex;
                        align-items: center;
                        margin-right: 16px;

                        &:last-child {
                            margin-right: 0;
                        }

                        @include only-phone {
                            width: 100%;
                            justify-content: center;
                            margin-right: 0;
                            margin-bottom: 20px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        img {
                            width: 21px;
                            height: 21px;
                            margin-right: 10px;
                        }
                    }
                }
            }

            .contact-us-img-wrapper {
                max-width: 496px;
                border-radius: 19px;
                overflow: hidden;
                margin-left: 30px;

                @include only-phone-lg {
                    width: 100%;
                    max-width: 550px;
                    height: 300px;
                    margin-bottom: 0;
                    margin-left: 0;
                }

                @include only-phone {
                    max-width: 100%;
                    height: 200px;
                }

                .contact-us-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .contact-us-particles{
                    width: 100%;
                    height: 750px;

                    @include only-phone-lg {
                        height: 100%;
                    }

                    #particles2{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}