@import "../../styles/_media-queries.scss";

.enhance-blockchain {
    padding: 110px 0;
    //background: linear-gradient(270deg, rgba(37, 37, 39, 0.00) 50%, #2A2A2F 101.67%) , url("../../assets/images/blockchain.png") right top / cover no-repeat;
    background:
    linear-gradient(to bottom, #11111300 70%, #111113ff 100%),
    linear-gradient(to top, #11111300 70%, #111113ff 100%),
    linear-gradient(270deg, rgba(37, 37, 39, 0.00) 50%, #2A2A2F 101.67%),
        url("../../assets/images/blockchain.png") right top / cover no-repeat;
        
    position: relative;

    @include only-phone {
        padding: 60px 0;
    }

    .eb-bg-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.8;
        object-fit: cover;
        object-position: right;
    }

    .enhance-blockchain-content {
        .eb-small-title {
            color: var(--Primary-2, #FF8C00);
            text-align: center;
            font-family: Poppins, sans-serif;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 1.62px;
            text-transform: uppercase;
            margin-bottom: 32px;

            @include only-phone {
                font-size: 12px;
                margin-bottom: 20px;
            }
        }

        .eb-title {
            text-align: center;
            font-family: Quantico, sans-serif;
            font-size: 59.684px;
            font-weight: 700;
            line-height: 73.895px;
            letter-spacing: -0.597px;
            margin: 0 auto;
            margin-bottom: 64px;
            max-width: 996px;

            @include only-phone-xl {
                font-size: 32px;
                line-height: 1.3;
            }

            @include only-phone-md {
                font-size: 32px;
                line-height: 1.3;
            }

            @include only-phone {
                font-size: 28px;
                line-height: 1.3;
                margin-bottom: 40px;
            }

            @include only-phone-xs {
                font-size: 24px;
            }
        }

        .eb-description {
            text-align: justify;
            font-family: Poppins, sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 46px;
            letter-spacing: -0.18px;
            max-width: 1280px;

            @include only-phone {
                font-size: 14px;
                line-height: 1.6;
            }
        }
    }
}