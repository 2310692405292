@import "../../styles/media-queries";

.roadmap-v2 {
    padding: 64px 0;
    padding-bottom: 120px;
    position: relative;

    @include only-phone-xl {
        padding: 40px 0 80px;
    }

    @include only-phone {
        padding: 32px 0 60px;
    }

    .roadmap-content {
        .small-title {
            color: var(--Primary-2, #FF8C00);
            text-align: center;
            font-family: Poppins, sans-serif;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 1.62px;
            text-transform: uppercase;
            margin-bottom: 16px;

            @include only-phone-xl {
                font-size: 16px;
                margin-bottom: 30px;
            }

            @include only-phone-md {
                font-size: 14px;
                margin-bottom: 20px;
            }

            @include only-phone {
                font-size: 12px;
                margin-bottom: 16px;
            }
        }

        .title {
            max-width: 744px;
            margin: 0 auto 130px;
            color: var(--White-var, #F4F4F4);
            text-align: center;
            font-family: Quantico, sans-serif;
            font-size: 59.684px;
            font-weight: 700;
            line-height: 73.895px;
            letter-spacing: -0.597px;

            @include only-phone-xl {
                font-size: 36px;
                line-height: 50px;
                margin-bottom: 50px;
            }

            @include only-phone {
                font-size: 28px;
                line-height: 40px;
                margin-bottom: 40px;
            }

            @include only-phone-xs {
                font-size: 24px;
            }
        }

        .roadmap-graph-wrapper {
            .roadmap-img {
                display: block;
                margin: 0 auto;

                @include only-phone-xl {
                    width: 100%;
                    height: auto;
                }

                @include only-phone-md {
                    display: none;
                }
            }

            .roadmap-img-mobile {
                display: none;
                margin: 0 auto;
                width: 100%;
                max-width: 400px;
                height: auto;

                @include only-phone-md {
                    display: block;
                }
            }
        }

    }

    .light-1 {
        border-radius: 50%;
        width: 425px;
        height: 437.274px;
        background: radial-gradient(50% 50% at 50% 50%, #FF8C00 0%, rgba(17, 17, 19, 0.00) 100%);
        mix-blend-mode: screen;
        position: absolute;
        right: -300px;
        top: 100px;
        z-index: -1;

        @include only-phone-md {
            height: 309px;
            right: -350px;
            top: 50px;
        }
    }

    .light-2 {
        border-radius: 50%;
        width: 425px;
        height: 437.274px;
        background: radial-gradient(50% 50% at 50% 50%, #FF8C00 0%, rgba(17, 17, 19, 0.00) 100%);
        mix-blend-mode: screen;
        position: absolute;
        left: -330px;
        top: 450px;
        z-index: -1;

        @include only-phone-md {
            height: 309px;
            left: -350px;
            top: 300px;
        }
    }
}