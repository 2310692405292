.btn {
    color: var(--White-var, #F4F4F4);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    padding: 15px 20px;
    background: none;
    border-radius: 50px;
    outline: 1px solid var(--Primary-2, #FF8C00);
}

.coolBeans {
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.2s transform ease-in-out, 0.2s box-shadow ease-in-out;
    will-change: transform;
    z-index: 0;
    box-shadow: 0 0 20px #e8800200;
}

.coolBeans::after {
    // cool gradient background
    background: linear-gradient(135deg, #e88002, #ffa435);
    border-radius: 3rem;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
}

.coolBeans:hover::after {
    transform: translate(0, 0);
}

.coolBeans:hover {
    outline: none;
    transform: scale(1.05);
    will-change: transform;
    box-shadow: 0 0 20px #e88002;
}