#root {
    overflow-x: hidden;
}

.content-wrapper {
    max-width: $xl-screen;
    margin: 0 auto;
    padding: 0 80px;

    @media (max-width: $xl-screen) {
        padding: 0 16px;
    }
}

.content-wrapper-lg {
    max-width: $xxl-screen;
    margin: 0 auto;
    padding: 0 80px;

    @media (max-width: $xxl-screen) {
        padding: 0 32px;
    }
}

.my-spacer {
    flex: 1;
}

.notransition {
    transition: none !important;
}

.light {
    pointer-events: none;
}

.highlited-text-color{
    color: #FF8C00;
}

/* glass shine */
.card-shine-effect {
    --shine-deg: 45deg;
    position: relative;
    background-repeat: no-repeat;
    background-position: -100% 0, 0 0;

    background-image: linear-gradient(var(--shine-deg),
            transparent 20%,
            transparent 40%,
            rgb(68, 68, 68, 0.2) 50%,
            rgb(68, 68, 68, 0.2) 55%,
            transparent 70%,
            transparent 100%);

    background-size: 250% 250%, 100% 100%;
    transition: background-position 0s ease;

    &:hover{
        background-position: 200%0, 0 0;
        transition-duration: 1000ms;
    }
}